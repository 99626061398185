<template>
  <div>
    <Header />
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <div class="rowBlock">
            <h5 class="MediumTxt TituloCor3">TRATAMENTO<br />ANTIRREFLEXO</h5>

            <h1 class="TituloCor3" id="TituloVisaoSimples">Hidrofóbica</h1>

            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios top10"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <div class="row top10">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Hidrofóbica</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
            <carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots="true"
              :responsive="{
                0: { items: 1, nav: false },
                450: { items: 1, nav: true },
                1000: { items: 1, nav: true },
              }"
            >
              <div class="item">
                <div class="row">
                  <img
                    src="./images/Antirreflexo/Banner_Agua_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    A aplicação do Tratamento Antirreflexo também garante as
                    lentes uma camada hidrofóbica, que faz com que as gotas de
                    água, da chuva por exemplo, escorram de forma mais rápida e
                    fácil, não ficando fixas na superfície das lentes e
                    atrapalhando a visão.<br />
                    Quando as lentes ficam com gotas de água em sua superfície
                    causam um grande incômodo pois provocam distorções e
                    prejudicam a nitidez da visão.
                  </p>
                </div>
              </div>

              <div class="item">
                <div class="row">
                  <img
                    src="./images/Antirreflexo/Banner_Agua_2.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Também facilitam no processo de limpeza, quando ao lavar em
                    água corrente, basta balançar os óculos para que as gotas de
                    água escorram das lentes e finalizar secando com um lenço de
                    microfibra.
                  </p>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <div class="AntirreflexoEscolha">
      <a
        @click="OpenLens('anti-glare/1')"
        class="BtnAntirreflexo"
        id="Chama_Reflexos"
      >
        <img
          src="./svg/Antirreflexo_Reflexos.svg"
          class="Icones"
          alt="Reflexos"
        />

        <h3>REFLEXOS</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/2')"
        class="BtnAntirreflexo"
        id="Chama_Riscos"
      >
        <img src="./svg/Antirreflexo_Riscos.svg" class="Icones" alt="Riscos" />

        <h3>RISCOS</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/3')"
        class="BtnAntirreflexo Antirreflexo_BtnActive"
        id="Chama_Agua"
      >
        <img src="./svg/Antirreflexo_Agua.svg" class="Icones" alt="Agua" />

        <h3>ÁGUA</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/4')"
        class="BtnAntirreflexo"
        id="Chama_Manchas"
      >
        <img
          src="./svg/Antirreflexo_Manchas.svg"
          class="Icones"
          alt="Manchas"
        />

        <h3>MANCHAS</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/5')"
        class="BtnAntirreflexo"
        id="Chama_Sujeira"
      >
        <img
          src="./svg/Antirreflexo_Sujeira.svg"
          class="Icones"
          alt="Sujeira"
        />

        <h3>SUJEIRA</h3>
      </a>
    </div>

    <Split style="height: 100vh; display: flex" @onDrag="onDrag">
      <SplitArea>
        <div class="split a" style="width: 100"></div>
      </SplitArea>
      <SplitArea>
        <div class="split b" style="width: 100"></div>
      </SplitArea>
    </Split>
  </div>
</template>

<script>
import Header from '@/views/menu/components/header.vue'
// import Buttons from '@/views/menu/components/anti_glare_buttons.vue'
import carousel from 'vue-owl-carousel'
import VueSplit from 'vue-split-panel'

import {
  InicialSet,
  DraggableButton,
} from '@/views/menu/js/new/DraggableButton_Scirpt'

import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

export default {
  methods: {
    DraggableButton,
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,

    OpenLens(route) {
      this.$router.push(`/lens/${route}`)
    },
  },
  components: {
    Header,
    carousel,
    // Buttons,
  },
  mounted() {
    InicialSet()
  },
}
</script>

<style scoped>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import "./css/plugins/owl.carousel.min.css"; */
@import './css/plugins/owl.theme.default.min.css';

@import './css/AntirreflexoAntirriscos_Style.css';

@import './css/plugins/images-compare.css';

@import './css/plugins/Compare_Agua.css';

.split {
  width: 101%;
  height: 100vh;
}
.a {
  background: url('./images/Antirreflexo/Antirreflexo_Agua_ComAgua.jpg') center
    center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.b {
  background: url('./images/Antirreflexo/Antirreflexo_Agua_SemAgua.jpg') center
    center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.gutter {
  background-color: red !important;
  width: 50px !important;
}
.gutter.gutter-horizontal {
  cursor: e-resize;

  left: 50% !important;
  margin-left: -25px !important;
  margin-right: -25px !important;
  margin-top: -25px !important;
  z-index: 5 !important;
  cursor: pointer !important;
  background: url('./svg/Setas.svg') center center no-repeat !important;
  background-size: 50px !important;
}
</style>
